.page-layout {
  position: relative;
  padding: 0 1.5rem;
  margin: -0.333rem -0.333rem 0;

  .header-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 200px;
    // background: #03a9f4;
    z-index: -1;
  }

  .content {
    display: flex;
    flex-direction: column;
  }
  .content-header {
    height: 136px;
  }
  .content-body {
  }

  .content-card {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

    & > .mat-tab-group .mat-tab-header .mat-tab-label,
    & > .card-header {
      height: 64px;
    }
    .card-header {
      display: flex;
      align-items: center;
    }
    .card-body {
    }
  }

  .sidebar-header {
    height: 200px;
  }

  &.plain {
    padding-left: 0;
    padding-right: 0;

    .sidebar-header {
      height: auto;
    }
    .content-card {
      box-shadow: none;
      border-radius: 0;
    }
  }
}

.mt-1 {
  margin-top: 10px !important;
}

.mb-1 {
  margin-bottom: 10px !important;
}

.mr-1 {
  margin-right: 10px !important;
}
.ml-1 {
  margin-left: 10px !important;
}
